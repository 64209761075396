/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.logo-img {
  filter: drop-shadow(2px 4px 6px);
}

.App-link {
  color: #61dafb;
}

.cursor-pointer {
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loginContainer {
  background-image: url('../public/assets/img/background.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 645px;
}

.login-box {
  width: 50%;
  display: block;
  margin: auto;
  padding: 10%;
}

/* common card css */

.card-style {
  overflow: hidden;
  margin: 0 16px 30px 16px;
  border-radius: 15px;
  border: none;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, .08);
}

.card {
  -webkit-transform: translate3d(0, 0, 0);
  background-size: cover;
  border: 0 !important;
  max-height: 150000px;
  margin-bottom: 30px;
  background-position: center center !important;
  border-radius: 15px !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  /* background-color: #fff; */
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
}

.content {
  margin: 20px 15px 20px 15px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.font-12 {
  font-size: 12px !important;
}

.opacity-30 {
  opacity: .3 !important;
}

.font-700 {
  font-weight: 700;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.mb-n1 {
  margin-bottom: -.25rem !important;
}

.mt-n2 {
  margin-top: -.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

h1 {
  font-size: 24px;
  line-height: 30px;
}

.divider {
  height: 1px;
  display: block;
  background-color: rgba(0, 0, 0, .05);
  /* margin-bottom: 30px; */
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.input-style {
  position: relative;
  margin-bottom: 10px !important;
}

.input-style.has-borders input,
.input-style.has-borders select,
.input-style.has-borders textarea {
  height: 53px;
  border-left-width: 1px !important;
  border-right-width: 1px !important;
  border-top-width: 1px !important;
  padding-left: 13px !important;
  padding-right: 10px !important;
  border-radius: 10px !important;
}

.input-style input,
.input-style select,
.input-style textarea {
  font-size: 12px;
  width: 100%;
  line-height: 45px;
  height: 45px;
  padding: 0;
}

input,
select,
textarea {
  border-color: rgba(0, 0, 0, .08) !important;
}

.btn,
input,
select {
  box-shadow: none !important;
  outline: 0 !important;
}

input {
  font-size: 12px !important;
}

.input-style p {
  color: #6c6c6c;
  margin-bottom: 0 !important;
  padding-bottom: 0;
}

@media print {

  .header,
  .noPrint {
    display: none;
  }

}

.cus-date {
  width: 385% !important;
  height: 53px !important;
}

/* 
.app {
  text-align: center;
  transition: background-color 0.5s, color 0.5s;
}

.app.light {
  background-color: white;
  color: black;
}

.app.dark {
  background-color: black;
  color: white;
}

.app-header {
  padding: 20px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
} */
.cus-input-text {

  padding: 0.2rem 0.7rem;
}

.cus-btn-group-append {
  padding: 0.2rem 0.6rem;
}

.cus-ps-10 {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

}

.cus-getdata-10 {
  margin-top: 34px;
}

.cus-takehelp-btn {
  padding: 0.2rem 0.7rem;
}

.cus-userid {
  margin-bottom: 5px !important;
}

.cus-pas {
  height: auto;
}

.btn-primary.btn-cus-main {

  padding: 2px 18px !important;
  font-size: 11px;

}

.transition {
  transition: all 0.8sec ease-in-out;
}

.transition:hover {
  scale: (1.2);
}

.footer-bar-1 .active-nav i, .footer-bar-1 .active-nav span {
  color: #da4453 !important;
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .logo-img {
    width: 100% !important;
  }

  .login-box {
    width: 100%;

  }

  .footer-tag {
    display: none !important;
  }

  .printableArea {
    padding: 0px 30px !important;
  }

  .table thead th {
    min-width: 75px;
  }

  .e-pinreport table thead th {
    min-width: 155px !important;
  }

  .p-5 {
    padding: 10px 15px;
  }
}
.custom-toast-mobile {
  margin-top: 30px!important;
  max-width: 100%;
  font-size: 12px;
}
.cus-close{
  position: absolute;
  top: 25px;
  right: 24px;
}
.cus-regis
{
  background: #dc9b06;
  padding: 9px;
  border-radius: 2px;
}
.cus-created0
{
  font-size: 15px;
}
.cus-credential-0
{
  text-align: center;
  line-height: 20px;
  margin-top: 10px;
}
.fade-Modalclass
{
  background: #00000080;
}